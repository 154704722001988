<template>
  <div class="box">
    <div class="columns">
      <div class="column is-6">
        <br>
        <b-message type="is-info" >
          <img src="@/assets/img/help/general/loupe.png"/>
          <strong>By clicking on “Export report”, you can export the results of
          one or several analyses in an HTML report</strong>. A zip file
          containing one or several HTML files (one for each selected analysis)
          will be created for download.<br><br>
          Analyses are selected as shown in the image on the right. <br>
          In some analysis screens, certain plots can be customized (for
          instance, on PCA, the plot of individuals can be colored by any
          variable). <strong>These plots must be saved individually</strong> if
          you want to include them in the report (using the button<br>
          <img class="img-help" :src="require('@/assets/img/help/report/add_to_report.png')" height="35"/> <br>
          <br>available on the corresponding screen). Saved additional plots are
          listed in the analysis menu, as shown on the image on the right.
        </b-message>
      </div>
      <div class="column is-4">
        <img class="bulle" src="@/assets/img/help/report/report_help.png"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FullExport'
}
</script>
