<template>
          <div class="box">
            <div class="multicouche">
              <img class="bulle couche1" src="@/assets/img/help/pca/bio-elbow-rule.png"/>
              <div class="content" style="padding-top: 510px;">
                <div class="columns couche2">
                  <div class="column is-one-thirds">
                    <br><br>
                    <img src="@/assets/img/help/pca/bio-reglette.png"/>
                  </div>
                  <div class="column is-two-thirds">
                    <img src="@/assets/img/help/pca/nb_pcs.png"/>
                  </div>
                </div>
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/loupe.png"/>
                  <strong>Percentage of explained variance</strong> reproduced
                  in your MFA must be as large as possible to ensure what you
                  see is a main trend of your dataset. Use the elbow rule
                  (screegraph) and try to keep at least 80% of cumulative
                  explained variance when you choose a number of principal
                  components (PCs).
                </b-message>
              </div >
            </div>
          </div>
</template>
<script>
export default {
  name: 'FullRMFAStep'
}
</script>
