<template>
        <div class="box">
          <div class="multicouche">
            <img class="bulle couche1" src="@/assets/img/help/diff-analysis/bio-check-p-values.png"/>
            <div class="content" style="padding-top: 340px;">
              <div class="columns couche2" style="padding-top: 20px;">
                <div class="column is-one-quarter">
                  <img src="@/assets/img/help/diff-analysis/histogram1.png"/>
                </div>
                <div class="column is-two-quarter">
                  <br>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    The shape of the raw p-value histogram is expected to be
                    similar to this figure (at the left).
                  </b-message>
                </div>
                <div class="column is-one-quarter">
                </div>
              </div>
            </div>
            <img class="bulle couche1" src="@/assets/img/help/diff-analysis/bio-reglette-1.png" style="padding-top: 150px;"/>
            <div class="content">
              <img src="@/assets/img/help/diff-analysis/histogram2.png"/>
              <div class="columns">
                <div class="column is-one-quarter">
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;(a)
                </div>
                <div class="column is-one-quarter">
                  &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;(b)
                </div>
                <div class="column is-one-quarter">
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;(c)
                </div>
              </div>
              <b-message type="is-info" >
                <img src="@/assets/img/help/general/attention-actif.png"/>
                A flat p-value histogram (as in a) indicates that very
                few (or no) variables are differentially expressed.
                Depletion of p-values (as in b) can indicate the presence
                of confounding hidden variables. A p-value histogram
                with one or more humps (as in c) can indicate that an
                inappropriate statistical test was used to compute the
                p-values, or that the dataset has a strong correlation
                structure.
              </b-message>
              <b-message type="is-info" >
                <img src="@/assets/img/help/general/go-inactif.png"/>
                If your categorical variable has more than two levels and
                that you have significant tests, go to the “Posthoc tests”
                panel to further explore these tests.
              </b-message>
            </div>
          </div>
        </div>
</template>
<script>
export default {
  name: 'FullRTestsMultiple'
}
</script>
