<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >

          <b-tab-item label="Numerical summary" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/explore/bio-variables-at-glance.png"/>
                <div class="content" style="padding-top: 570px;">
                  <img class="couche2" src="@/assets/img/help/explore/numerical-summary.png" style="height:59%"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    This table contains the same indicators as the numerical summary
                    for one variable (check the help page in this tab for further
                    information).<br> Only the last column “Comment” is new: it may warn
                    you about a specific behavior of some variables. For instance,
                    if one variable supposed to be numeric has only a few distinct
                    values, it might be categorical. Go in the “Edit” menu to set
                    its type properly.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Normality test">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/explore/bio-interpret-tests.png"/>
                <div class="content" style="padding-top: 480px;">
                  <img class="couche2" src="@/assets/img/help/explore/normality-test.png" style="padding-top: 30px; height:56%"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    This table contains the same information as the normality test
                    for one variable. Only the last column is new: it contains the
                    <i>p</i>-value adjusted for multiple testing. This adjusted value
                    should be taken into account (instead of the raw <i>p</i>-value)
                    to draw a global conclusion.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Plots">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/explore/Bio-boxplots.png"/>
                <div class="content" style="padding-top: 680px;">
                  <img class="couche2" src="@/assets/img/help/explore/all-variables-dataset.png" style="padding-top: 30px;"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    Parallel boxplots provide a <strong>global overview of the
                    distributions of all variables</strong> at a glance. You can
                    identify variables which, in comparison to the others, have:
                    <li>large or small values</li>
                    <li>large variability</li>
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

      </b-tabs>
</template>
<script>
export default {
  name: 'FullRUnivariateDataset'
}
</script>
