<template>
          <div class="box">
            <div class="content">
              <b-message type="is-info" >
                <img src="@/assets/img/help/general/loupe.png"/>
                <strong>How to set options?<br></strong>
        Imputation method can be chosen according to what you know on the missing
        values and subsequent analyses:
        <ul>
          <li><strong>imputation by zeros</strong> is dedicated to cases where
          missing values are due to a measurements below the detection
          threshold. It is a very basic approach to address this problem but
          certainly not the best;</li>
          <li><strong>imputation by PCA</strong> is well designed when you want
          to use PCA, MFA, or PLS (for instance) afterwards because it best
          preserves the projection of your individuals on PC axes;</li>
          <li><strong>imputation by k-nearest neighbors</strong> is based on
          the idea that two individuals that are similar on observed values also
          have similar values for unobserved variables. It best preserves the
          distances between individuals and is well adapted prior clustering.</li>
        </ul>
        The last two methods are only valid when data are missing at random.<br>
        <br>
        In addition, for PCA and KNN you can choose to impute <strong>only
        certain types of variables</strong> (only numerical or only categorical
        variables). Setting this option to “Auto”  imputes only variables
        of the most present type while “Mixed” imputes both numerical and
        categorical variables.
      </b-message>
              <b-message type="is-info">
                <img src="@/assets/img/help/general/loupe.png"/>
                When the resulting dataset is satisfactory, save it by clicking on
                <img style="height:100% !important;float:none;  margin-left: 0px;" src="@/assets/img/help/missing/icone-extract-inactif.png">
                .
              </b-message>
            </div>
          </div>
</template>
<script>
export default {
  name: 'FullRMissingImpute'
}
</script>
