<template>
  <div class="help">
      <component :is="helpComponent" />
  </div>
</template>

<script>
export default {
  name: 'help',
  props: {
    wfclass: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      helpComponent: null
    }
  },
  mounted () {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (this.wfclass) {
      try {
        require('@/components/help/secondlevel/Full' + this.wfclass + '.vue')
        this.helpComponent = () => import('@/components/help/secondlevel/Full' + this.wfclass + '.vue')
      } catch (e) {
        alert('Ahaha to be developped! ' + '@/components/help/secondlevel/Full' + this.wfclass + '.vue')
        this.helpComponent = null
      }
    } else {
      this.helpComponent = null
    }
    loadingComponent.close()
  }

}
</script>
<style>
  .help> .b-tabs li.is-active {
    font-weight: bold;
    border-bottom:  1px solid;
    border-bottom-color: #1896E1;
    color: #1896E1 !important;
  }
  .help> .b-tabs li.is-active a {
    font-weight: bold;
    color: #1896E1 !important;
    border-bottom-color: #1896E1
  }
  .help .tabs {
    background-color: white;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 29;
        border-bottom: 1px solid rgba(24, 28, 33, 0.06);
            padding-top: 10px;
  }
  .help  .content {
  padding-left :200px;
  padding-right :200px;

  font-size: 1rem;
}
.help .tab-content {
  padding-top: 60px;
}

.title-help {
  font-weight: 600;
  font-size: 18px !important;
  color: #000000!important;
  background-color: white;
  margin-left:-200px;
}
.help .message.is-info {
  margin-top:20px;
  margin-bottom:20px;
}

.help .message.is-info .media-content{
  margin-left: 50px;
}

.help .message.is-info  img {
    height: 40px;
    float: left;
    padding-right:10px;
    margin-left: -50px;
}
.bulle {
  float:left;
}

.multicouche {
  position: relative; /*l'élément est positionné mais il se place tout seul*/
}

.couche1 {
   z-index: 1;
}
.couche2 {
  z-index: 2;
  position:absolute;
  top:120px;
  left:200px;
}
</style>
