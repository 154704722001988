<template>
        <div class="box">
        <div class="content">
          <b-message type="is-info" >
            <img src="@/assets/img/help/general/go-inactif.png"/>You can create
            a new dataset that contains either the results of one of your
            differential analysis (table with test statistics and p-values) or a
            subset of your original dataset containing only variables found
            differentially expressed (“Sign” can also be used to select only
            over- or under- expressed variables). Once done, the datasets are
            available in “Workspace”.
          </b-message>
          <b-message type="is-info" >
            <img src="@/assets/img/help/general/attention-actif.png"/>Do not
            perform between sample normalization on a subset of your original
            variables. ASTERICS do not forbid it but it is not statistically
            correct.
          </b-message>
        </div>
      </div>
</template>
<script>
export default {
  name: 'FullRExtractDiff'
}
</script>
