<template>
  <b-tabs
    position="is-centered"
    size="is-medium"
    type="is-boxed"
  >
    <b-tab-item label="Interpret axes">
      <div class="box">
        <div class="multicouche">
          <img class="bulle couche1" src="@/assets/img/help/pca/bio-interpretation.png"/>
          <div class="content" style="padding-top: 200px;">
            <b-message type="is-info" >
              <img src="@/assets/img/help/general/loupe.png"/>
              The interpretation of PCA is done component
              (axis) by component, starting from the first which displays the
              <strong>main sources of variability between variables</strong>.
              <br><br>
              <img style="width:80%; height:80%; margin-left:100px" src="@/assets/img/help/pca/pca-animation.gif"/>
            </b-message>
            <b-message type="is-info" >
              <img src="@/assets/img/help/general/attention-actif.png"/>
              Only variables well correlated with axes can be interpreted.
              Select a correlation threshold to display the most
              correlated variables.
            </b-message>
          </div>
        </div>
      </div>
    </b-tab-item>
    <b-tab-item label="Case of categorical variables">
             <div class="box">
              <div class="content">
                <b-message type="is-info">
                  <img src="@/assets/img/help/general/attention-actif.png"/>
                  For FAMD (PCA with categorical variables), the levels
                  of the categorical variables are displayed in a space which
                  scale is not comparable to the correlation plot of numerical
                  variables.<br>
                  You can explore them on an additional plot.<br><br>
                  Distances in this space are interpreted in terms of <strong>
                  common co-occurrence of the two levels among
                  individuals</strong> (the closer the two levels on the plot,
                  the higher the co-occurrence of these levels).<br><br>
                  Only levels of categorical variables for which the
                  reproduced correlation ratio is larger than the chosen
                  threshold are displayed.
                </b-message>
              </div>
            </div>
          </b-tab-item>
  </b-tabs>
</template>
<script>
export default {
  name: 'FullRPlotvar'
}
</script>
