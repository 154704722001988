<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Quality plots">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/clustering/bio-clusters.png"/>
                <div class="content" style="padding-top: 220px;">
                  <div class="columns">
                    <div class="column is-half">
                      <img src="@/assets/img/help/clustering/silhouette.png" style="height:100%"/>
                    </div>
                    <div class="column is-half">
                      <br><br><br>
                      <img src="@/assets/img/help/clustering/within-dispersion.png" style="width:90%"/>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-half">
                      <b-message type="is-info" >
                        <img src="@/assets/img/help/general/loupe.png"/>
                        The <strong>silhouette</strong> is a quality criterion
                        for the clustering (the higher the silhouette, the better
                        the clustering).
                      </b-message>
                    </div>
                    <div class="column is-half">
                      <b-message type="is-info" >
                        <img src="@/assets/img/help/general/loupe.png"/>
                        The <strong>within-dispersion</strong> is a measure of
                        homogeneity of the clusters (the smaller the
                        within-dispersion, the more similar the individuals within
                        clusters).
                        <br>
                        In addition, the <strong>broken stick</strong> heuristic
                        suggests a number of clusters (without statistical
                        guarantee).
                      </b-message>
                    </div>
                  </div>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/go-inactif.png"/>
                    <strong>Once the number of clusters chosen</strong>, go to
                    the tab “Make and explore clusters” to visualize them.<br>
                    If you used hierarchical clustering (HC), you might also
                    want to check the “HC” tab of the help before.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="HC">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/clustering/bio-dendogram.png"/>
                <!--<iframe width="720" height="480" src="http://www.nathalievialaneix.eu/teaching/biostat1/img/dendogramme-animation.mp4" frameborder="0" allowfullscreen></iframe>-->
                <div class="content" style="padding-top: 165px;">
                  <b-message type="is-info">
                    <img src="@/assets/img/help/general/loupe.png"/>
                    Individuals are positioned at the bottom of the figure and
                    distances between individuals are read by following the
                    branches (not using horizontal distance).<br>
                    In ASTERICS, HC is performed with Euclidean distance and
                    Ward's linkage.
                  </b-message>
                  <img src="@/assets/img/help/clustering/dendogramme.png"/>
                  <img src="@/assets/img/help/clustering/dendrogamme-animation.gif"/>
                </div>
              </div>
            </div>
          </b-tab-item>
      </b-tabs>
</template>
<script>
export default {
  name: 'FullRClusteringStep'
}
</script>
