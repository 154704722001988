<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Color" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/som/bio-interpret-prototypes.png"/>
                <div class="content" style="padding-top: 440px;">
                  <img class="couche2" src="@/assets/img/help/som/color.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    Prototypes are special individuals, representative of
                    their cluster. They are approximately computed as centers
                    of gravity of the individuals in this cluster.<br>
                    <br>
                    The color level represents the value of the selected
                    numerical variable for the cluster prototype. If colors
                    are smoothly arranged on the map (as in figure on the right),
                    it means that this variable has a strong impact on cluster
                    organization. On the contrary, if colors are randomly
                    scattered on the map (as in figure on the left), it means
                    that the selected variable is not useful to explain the
                    map organization.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Multidimensional scaling plot (MDS)">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/som/bio-map.png"/>
                <div class="content" style="padding-top: 480px;">
                  <img class="couche2" src="@/assets/img/help/som/mds.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    In MDS plots, prototypes should be <strong>roughly organized
                    as in the original grid</strong>, up to rotation, symmetry
                    or local distance distortions. A MDS plot as in the figure
                    on the left indicates a good organization of the map, whereas
                    a MDS plot as in figure on the right indicates a poor
                    organization of the map.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Distances with polygon">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/som/bio-clusters-similar.png"/>
                <div class="content" style="padding-top: 620px;">
                  <img class="couche2" src="@/assets/img/help/som/distance-with-polygon.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    This plot can be used to represent distances between
                    neighboring clusters and cluster frequencies:
                    <li><strong>distances between neighboring clusters</strong>
                    are represented by the distances between polygons corners.
                    For instance, in this example, clusters 7 and 8 are closer
                    (their prototypes(*) are more similar) than clusters 7 and
                    12;</li>
                    <li><strong>the number of individuals classified in each
                    cluster</strong> is represented by the color level: the
                    darker the color, the smaller this number.</li>
                    <br>
                    <i>(*) Prototypes are special individuals, representative
                    of their cluster. They are approximately computed as centers
                    of gravity of the individuals in this cluster.</i>
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

      </b-tabs>
</template>
<script>
export default {
  name: 'FullRPlotSomProto'
}
</script>
