<template>
          <div class="box">
            <div class="multicouche">
              <img class="bulle couche1" src="@/assets/img/help/mfa/bio-group-position.png"/>
              <div class="content" style="padding-top: 660px;">
                <img class="couche2" src="@/assets/img/help/mfa/interpret-groups.png" style="padding-top: 75px;"/>
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/loupe.png"/>
                  Groups are <strong>interpreted similarly to individuals and
                  variables</strong> except that they can only have positive
                  coordinates (so a strong contribution is on the right or on
                  the top of the figure).
                </b-message>
              </div>
            </div>
          </div>
</template>
<script>
export default {
  name: 'FullRPlotgroup'
}
</script>
