<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >

          <b-tab-item label="Two numerical variables" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/explore/bio-search-patterns.png"/>
                 <div class="has-text-centered">
                  <br><br>
                  <span class="title-help">How to interpret <a href="#plot">plots</a>
                  and <a href="#tests">statistical tests</a> for two numerical
                  variables?</span>
                </div>
                <div class="content" style="padding-top: 160px;">
                  <h4 class="title is-4 couche2" id="plot" style="padding-top: 60px;">Plots</h4>
                  <strong>Scatterplots</strong> are useful to identify
                  <strong>relationships between two numerical
                  variables</strong>.<br><br>
                  <div class="columns">
                    <div class="column is-2">
                      <br>
                      <img src="@/assets/img/help/general/bio-reflexion.png" style="height:80%"/>
                    </div>
                    <div class="column is-4">
                      <br>
                      <b-message type="is-info" >
                        <img src="@/assets/img/help/general/loupe.png"/>
                        Depending on the shape of the scatterplot, the two
                        corresponding variables can be seen as:
                      </b-message>
                    </div>
                    <div class="column is-6">
                      <img src="@/assets/img/help/explore/not-related.png"/>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-half">
                      <img src="@/assets/img/help/explore/linearly-related.png"/>
                    </div>
                    <div class="column is-half">
                      <img src="@/assets/img/help/explore/nonlinearly-related.png"/>
                    </div>
                  </div>
                  <strong>Scatterplots</strong> are also useful to <strong>highlight
                  outliers</strong>.
                  <br><br>
                  <div class="columns">
                    <div class="column is-8">
                      <img src="@/assets/img/help/explore/highlighted-point.png"/>
                    </div>
                    <div class="column is-4">
                      <br>
                      <b-message type="is-info" >
                        <img src="@/assets/img/help/general/loupe.png"/>
                        This type of point can not be identified as a outlier when
                        only looking at one of the distributions of the two
                        variables but it appears as an outlier on the scatterplot
                        because it is outside of the trend between the two
                        variables.
                      </b-message>
                    </div>
                  </div>
                  <h4 class="title is-4" id="tests">Statistical tests</h4>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    When the Pearson correlation coefficient is lower than the
                    Spearman correlation coefficient, this indicates a potential
                    nonlinear relationship between the two variables.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="One numerical and one categorical variables">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/explore/bio-understand-results.png"/>
                <div class="has-text-centered">
                  <br><br>
                  <span class="title-help">How to interpret <a href="#plot2">plots</a>
                  and <a href="#tests2">statistical tests</a> for two numerical
                  variables?</span>
                </div>
                <div class="content" style="padding-top: 110px;">
                  <h4 class="title is-4 couche2" id="plot2" style="padding-top: 30px;">Plots</h4>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    The plots are the same than plots obtained in tab “1 variable”,
                    but displayed in parallel (stripchart, boxplot and violin) or
                    superimposed (density), with respect to the levels of the
                    categorical variable.
                  </b-message>
                  <h4 class="title is-4" id="tests2" style="padding-top: 20px;">Statistical tests</h4>
                  <img src="@/assets/img/help/explore/numerical-categorical-variables-2.png"/><br>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    <li>The (global) Fisher test (ANOVA) tests the following H0
                    hypothesis: “For all the levels of the categorical variable,
                    the average value of the numerical variable is the same”.
                    This test is based on normality (Gaussian distribution)
                    assumptions of the numerical variable.</li><br>
                    <li>The Kruskal-Wallis test is a nonparametric test (that does
                    not require gaussianity) that is an alternative to ANOVA. It
                    tests (globally) the following H0 hypothesis: “For all the
                    levels of the categorical variable, the distribution of the
                    numerical variable is the same".</li>
                  </b-message>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/attention-actif.png"/>
                    If ANOVA and KW disagree (two very different <i>p</i>-values,
                    one smaller than 0.01 and the other larger than 0.5), investigate
                    to identify the reason of the mismatch (non gaussianity,
                    presence of an outlier, …).
                  </b-message>
                  <hr>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    This plot is a graphical output of the post-hoc tests (tests
                    performed on pairs of levels of the categorical variable when
                    it has more than two levels and if the global test for 1-way
                    ANOVA is found significant). The segments represent the
                    confidence interval for each pairwise comparison: if 0 belongs
                    to the interval the difference between the two means is not
                    significant.
                  </b-message>
                  <img src="@/assets/img/help/explore/numerical-categorical-variables-1.png"/><br>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/go-inactif.png"/>
                    If the categorical variable has more than two levels, post-hoc
                    tests provide the result of the pairwise comparison tests between
                    levels. See the corresponding graphical output in “Plots”.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Two categorical variables">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/explore/bio-categorical-variables.png"/>
                <div class="has-text-centered">
                  <br><br>
                  <span class="title-help">How to interpret <a href="#plot3">plots</a>
                  and <a href="#tests3">statistical tests</a> for two categorical
                  variables?</span>
                </div>
                <div class="content" style="padding-top: 110px;">
                  <h4 class="title is-4 couche2" id="plot3" style="padding-top: 40px;">Plots</h4><br>
                  These plots are three visualizations of the cross table:
                  <br><br>
                  <div class="columns">
                    <div class="column is-two-fifths">
                      <img src="@/assets/img/help/explore/barplot-1.png"/>
                    </div>
                    <div class="column is-three-fifths">
                      <b-message type="is-info">
                        <img src="@/assets/img/help/general/loupe.png"/>
                        These barplots display either the counts (maximal vertical
                        value = maximal count for one level) or the frequencies
                        (maximal vertical value = 1) for one categorical variable
                        (horizontal axis) stacked by the other categorical variable
                        (color).
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-two-fifths">
                      <img src="@/assets/img/help/explore/barplot-2.png"/>
                    </div>
                    <div class="column is-three-fifths">
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-two-fifths">
                      <img src="@/assets/img/help/explore/barplot-3.png"/>
                    </div>
                    <div class="column is-three-fifths">
                      <b-message type="is-info">
                        <img src="@/assets/img/help/general/loupe.png"/>
                        The barplot for counts can also be displayed using several
                        side-by-side bars, instead of stacked bars. This representation
                        can be useful for the comparison of counts between levels.
                      </b-message>
                    </div>
                  </div>
                  <h4 class="title is-4" id="tests3">Statistical tests</h4>
                  <img src="@/assets/img/help/explore/statistical-tests.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    <li>Pearson’s Chi-squared test and Fisher’s exact test for
                    count data are the two most used tests to check for the
                    independence between two categorical variables.</li>
                    <li>Pearson’s Chi-squared test should be avoided when the
                    sample size is small or when some categories are not
                    frequently observed.</li>
                    <li>Fisher’s exact test is generally preferable but can be
                    impossible to use when the sample size is large.</li>
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

      </b-tabs>
</template>
<script>
export default {
  name: 'FullRBivariate'
}
</script>
