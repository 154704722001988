<template>
      <div class="box">
            <div class="columns">
                    <div class="column is-4">
                      <img src="@/assets/img/help/diff-analysis/differential-analysis.png"/>
                    </div>
                    <div class="column is-8">
                      <br>
                      <b-message type="is-info" >
                        <img src="@/assets/img/help/general/loupe.png"/>
                        <strong>Differential analysis uses two
                        datasets</strong>: X contains numeric variables in
                        columns (<em>e.g.</em>, gene expression), which have to
                        be tested, and Y contains one column (other columns can
                        be present but will not be used) that is a categorical
                        variable defining the conditions of the test
                        (<em>e.g.</em>, treatments or other information on the
                        design of the experiment). In short, each row of Y
                        describes the condition of the corresponding
                        individual.<br>
                        Then, differential analysis <strong>tests the equality
                        of means</strong> for each column of X accross the
                        groups of individuals defined by the values of Y.<br>
                        <br>
                        Since X and Y are two different datasets (dataset1 and
                        dataset2 below), <strong>only individuals common to
                        X and Y</strong> are used in this analysis.
                      </b-message>
                    </div>
                  </div>
                  <br>
            <div class="multicouche">
              <img class="bulle couche1" src="@/assets/img/help/mfa/bio-combine.png"/>
              <div class="content" style="padding-top: 940px;">
              <div class="has-text-centered couche2">
                <img src="@/assets/img/help/mfa/venn-diagram.png" style="padding-top: 30px;"/>
                <br><br>
                <img src="@/assets/img/help/mfa/upset-plots.png"/>
              </div>
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/loupe.png"/>
                  <strong>Venn diagram</strong> and <strong>upset
                  plots</strong> are used to understand how many individuals
                  are common / specific to your numerical dataset and non
                  missing entries of your target variable.
                </b-message>
              </div>
            </div>
          </div>
</template>
<script>
export default {
  name: 'FullRCombineDiff'
}
</script>
