<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Color" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/som/bio-interpret-map.png"/>
                <div class="content" style="padding-top: 440px;">
                  <img class="couche2" src="@/assets/img/help/som/color.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    The color level represents the value of the selected
                    numerical variable in the corresponding cluster. If colors
                    are smoothly arranged on the map (as in figure on the right),
                    it means that this variable has a strong impact on cluster
                    organization. On the contrary, if colors are randomly it
                    means that the selected variable is not useful to explain
                    the map organization.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Boxplot">
            <div class="box">
              <div class="content">
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/loupe.png"/>
                  Similarly to “Color”, “Boxplot” can be used to identify differences
                  in the distribution of up to five variables between clusters. They
                  can help interpret clusters or understand the map organization.
                </b-message>
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/attention-actif.png"/>
                  To allow for several variables with possibly different scales
                  to be displayed at once, note that variable values are centered
                  to zero mean and scaled to unit variance in this plot.
                </b-message>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Names">
            <div class="box">
              <div class="content">
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/loupe.png"/>
                  If the number of individuals in your dataset is no more than
                  100, the individual names (row names) can be directly displayed
                  on the map.
                </b-message>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Pie">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/som/bio-combine-map.png"/>
                <div class="content" style="padding-top: 520px;">
                  <img class="couche2" src="@/assets/img/help/som/pies.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    The pie chart represents the distribution of the selected
                    categorical variable in the corresponding clusters. If pies
                    tend to be mostly made of a individuals with the same level
                    for the categorical variables (as in figure on the right),
                    it means that this variable has a strong impact on cluster
                    organization. On the contrary, if pies are composed of multiple
                    levels (as in figure on the left), it means that the selected
                    variable is not useful to explain the map organization.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

      </b-tabs>
</template>
<script>
export default {
  name: 'FullRPlotSomInd'
}
</script>
