<template>
          <div class="box">
            <div class="content">
              <b-message type="is-info" >
                <img src="@/assets/img/help/general/loupe.png"/>
                <strong>Correlation</strong>:
                <ul>
                  <li>The correlation matrix of a dataset represent
                    <strong>Pearson correlation</strong> for each pair of
                    variable of a dataset;</li>
                  <li> The correlation can be filtered in absolute value to
                    show only the largest values (positive or negative).</li>
                </ul>
              </b-message>
            </div>
          </div>
</template>
<script>
export default {
  name: 'FullRCorrPlot'
}
</script>
