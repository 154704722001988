<template>
  <div class="box">
    <div class="multicouche">
      <img class="bulle couche1" src="@/assets/img/help/pca/bio-interpretation.png"/>
      <div class="content" style="padding-top: 200px;">
        <b-message type="is-info" >
          <img src="@/assets/img/help/general/loupe.png"/>
          Similarly to PCA, the interpretation of PLSDA is done
          component (axis) by component, starting from the first which
          displays the <strong>main trends, in the numerical dataset,
          separating the individuals with respect to their levels for
          the target variable</strong> (PLS-DA).
          <br><br>
          <img style="width:80%; height:80%; margin-left:100px;" src="@/assets/img/help/pca/pca-animation.gif"/>
        </b-message>
        <b-message type="is-info" >
          <img src="@/assets/img/help/general/attention-actif.png"/>
          Only variables well correlated with axes can be interpreted.
          Select a correlation threshold to display the most
          correlated variables.
        </b-message>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FullRPlotvar'
}
</script>
