<template>
          <div class="box">
            <div class="content">
              <b-message type="is-info" >
                <img src="@/assets/img/help/general/loupe.png"/>
                <strong>Two types of PLS exist</strong>:
                <ul>
                  <li>in the “canonical” mode, the objective is to unravel the
                  main relations between the two numeric datasets in a
                  <strong>symmetric</strong> manner;</li>
                  <li>in the “regression” mode, the objective is to
                  <strong>explain at best</strong> one of the two datasets from
                  the other.</li>
                </ul>
                In ASTERICS, the performed analysis is of the
                <strong>“canonical” mode</strong> unless one of the two datasets
                <strong>has only one or two variables</strong> (columns). In
                this case, PLS is automatically performed in
                <strong>“regression” mode</strong> with this dataset the one to
                be explained from the other.
              </b-message>
              <b-message type="is-info" >
                <img src="@/assets/img/help/general/attention-actif.png"/>
                Contrary to PCA, <strong>reproduced inertia is not an objective
                of PLS</strong>. These plots are given as mere information but
                can not be directly use to choose the number of PLS components.
              </b-message>
            </div>
          </div>
</template>
<script>
export default {
  name: 'FullRPLSStep'
}
</script>
