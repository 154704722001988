<template>
    <div class="box">
      <div class="content">
        <b-message type="is-info" >
          <img src="@/assets/img/help/general/loupe.png"/>
          The answers to both questions <strong>“Have data been log-transformed?”</strong> and <strong>“Have data been normalized?”</strong> can affect the way your differential analyses are performed (for instance). Normalization is to be understood as “the samples have been transformed so as to be comparable”. It is thus important to set them well for certain types of data:
          <ul>
            <li> <strong>microarray data</strong>: standard normalization for microarray data is a between-sample quantile normalization followed by a log transformation. If your data have already been (at least partially) processed that way, you should consider them normalized and/or log-transformed;</li>
            <li> <strong>RNA-seq and other count data</strong>: it is advised to import raw count data. However, if your data have been previously normalized (with library size normalization for instance) and/or log-transformed, you should reflect it at this stage. The differential analyses will then be adapted to that situation (and could thus be under-optimal);</li>
            <li> <strong>compositional data</strong> (proportions like in metagenomics for instance): standard normalization methods include transformations that make them usable for standard euclidean data analysis methods (like CLR or ILR). These transformations are recommended even before simple exploratory analyses for this type of data. If your data have already been processed that way, you should consider them both normalized and log-transformed.</li>
          </ul>
          Furthermore, ASTERICS proposes other normalization methods, including <strong>methods to correct batch effects</strong> (in continuous or count data). If your data have already been processed that way, you should consider them normalized, but it is a better strategy to perform that type of normalization in ASTERICS directly. Other normalization methods than the ones cited above are not well handled by ASTERICS.<br>
        </b-message>
        <b-message type="is-info" >
          <img src="@/assets/img/help/general/attention-actif.png"/>
          <p>Note that <strong>metagenomics data</strong> can be uploaded either as count data or compositional data. In the first case, normalization using RNA-seq techniques or prior computation of proportions before compositional normalization are two valid strategies.</p>
        </b-message>
      </div>
    </div>
</template>
<script>
export default {
  name: 'FullImportAttributes'
}
</script>
