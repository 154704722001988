<template>
  <div class="box">
    <div class="multicouche">
      <img class="bulle couche1" src="@/assets/img/help/pca/bio-interpretation.png"/>
      <div class="content" style="padding-top: 200px;">
        <b-message type="is-info" >
          <img src="@/assets/img/help/general/loupe.png"/>
          Similarly to PCA, the interpretation of PLS is done
          component (axis) by component, starting from the first which
          displays the <strong>main relationships between
          variables</strong> from the two datasets (PLS).
          <br><br>
          <img style="width:80%; height:80%; margin-left:100px;" src="@/assets/img/help/pca/pca-animation.gif"/>
        </b-message>
        <b-message type="is-info" >
          <img src="@/assets/img/help/general/attention-actif.png"/>
          Only variables well correlated with axes can be interpreted.
          Select a correlation threshold to display the most
          correlated variables.
        </b-message>
        <b-message type="is-info" >
        <img src="@/assets/img/help/general/attention-actif.png"/>
        If you performed <strong>PLS</strong> in
        canonical mode (meaning that your two datasets both have more
        than 2 variables), you may display the variables from the two
        datasets using “Separated” to improve the readability of the
        plots but be careful that <strong>the interpretation has to
        be done with overlapping X- and Y-
        representations</strong>.<br>
        In regression mode, on the contrary, only the X-representation
        is available.
      </b-message>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FullRPlotvar'
}
</script>
