<template>
    <div class="box">
      <div class="multicouche">
        <img class="bulle couche1" src="@/assets/img/help/mfa/bio-interpretation.png"/>
        <div class="content" style="padding-top: 200px;">
          <b-message type="is-info" >
          <img src="@/assets/img/help/general/loupe.png"/>
          Similarly to PCA, the interpretation of MFA is done component
          (axis) by component, starting from the first which displays the
          <strong>main sources of variability between variables</strong>.<br><br>
          <img style="width:80%; height:100%; margin-left:100px" src="@/assets/img/help/mfa/mfa-animation.gif"/>
        </b-message>
            <b-message type="is-info" >
            <img src="@/assets/img/help/general/loupe.png"/>
            Combine the plot of individuals with colors (or shapes /
            sizes) giving information on other variables (e.g.,
            variables of your design) to check if colors are organized
            with respect to the components. This would mean that the
            main relationships between the variables from your two input
            datasets are also associated with the variable that has
            been used to color individuals in your plot.
            <div class="columns">
            <div class="column is-one-half">
              <img style="width:100%; height:100%; margin-left:0px" src="@/assets/img/help/pca/design-tidy.png"/>
            </div>
            <div class="column" style="margin-left:0px">
              <img style="width:100%; height:100%; margin-left:0px" src="@/assets/img/help/pca/design-messy.png"/>
            </div>
          </div>
          </b-message>
        </div>
        <img class="bulle" src="@/assets/img/help/mfa/bio-group-contribution.png"/>
        <div class="content" style="padding-top: 200px;">
          <b-message type="is-info" >
            <img src="@/assets/img/help/general/loupe.png"/>
            <div class="columns">
            <div class="column is-one-third">
              For a given group, you can display the <strong>group
              contribution for every individual</strong>.<br>
              It gives the position of this individual if a PCA had
              been performed only with the dataset corresponding to
              the selected group).
            </div>
            <div class="column">
              <img style="width:80%; height:100%; margin-left:50px" src="@/assets/img/help/mfa/projection-of-individuals-1.png"/>
            </div>
          </div>
          </b-message>
          <b-message type="is-info" >
            <img src="@/assets/img/help/general/loupe.png"/>
            <div class="columns">
            <div class="column is-one-third">
              For some selected individuals, you can also display the
              <strong>contribution of all groups at once</strong> for these
              individuals.
            </div>
            <div class="column">
              <img style="width:80%; height:100%; margin-left:50px" src="@/assets/img/help/mfa/projection-of-individuals-2.png"/>
            </div>
          </div>
          </b-message>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'FullRPlotind'
}
</script>
