<template>
  <div class="box">
    <div class="multicouche">
      <img class="bulle couche1" src="@/assets/img/help/mfa/bio-interpretation.png"/>
      <div class="content" style="padding-top: 200px;">
        <b-message type="is-info" >
          <img src="@/assets/img/help/general/loupe.png"/>
          Similarly to PCA, the interpretation of PLS is done
          component (axis) by component, starting from the first which
          displays the <strong>main relationships between
          variables</strong> from the two datasets (PLS) or the
          <strong>main trends, in the numerical dataset, separating
          the individuals with respect to their levels for the target
          variable</strong> (PLS-DA).<br><br>
          <img style="width:80%; height:100%; margin-left:100px" src="@/assets/img/help/pca/pca-animation.gif"/>
        </b-message>
        <b-message type="is-info" >
          <img src="@/assets/img/help/general/loupe.png"/>
          Combine the plot of individuals with colors (or shapes /
          sizes) giving information on other variables (e.g.,
          variables of your design) to check if colors are organized
          with respect to the components. This would mean that the
          main relationships between the variables from your two input
          datasets are also associated with the variable that has
          been used to color individuals in your plot.
          <div class="columns">
            <div class="column is-one-half">
              <img style="width:100%; height:100%; margin-left:0px" src="@/assets/img/help/pca/design-tidy.png"/>
            </div>
            <div class="column" style="margin-left:0px">
              <img style="width:100%; height:100%; margin-left:0px" src="@/assets/img/help/pca/design-messy.png"/>
            </div>
          </div>
          In <strong>regression mode</strong> (when one of the two datasets has
          only one or two variables), the variables to explain (the one in the
          dataset with one or two variables) are represented by the color and
          size of the dots in the plot of individuals if the user did not make
          another choice.
        </b-message>
        <b-message type="is-info" >
        <img src="@/assets/img/help/general/loupe.png"/>
        In <strong>regression mode</strong> (when one of the two
        datasets has only one or two variables), individuals are
        projected on the X-variate space only, whereas in
        <strong>canonical mode</strong>, they are projected on a
        consensual XY-variate space.
      </b-message>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FullRPlotindPLS'
}
</script>
