<template>
            <div class="box">
              <img class="bulle" src="@/assets/img/help/missing/bio-remove.png"/>
              <div class="content">
                <b-message type="is-info" >
                <ul>
                <li>“Direction” is used to choose to remove either entire individuals
                (rows) or entire variables (columns).</li>
                <li>The chosen percentage corresponds to the maximum acceptable
                missingness. For instance, if 30% is chosen, it means that all
                individuals or variables with more than 30% of missing values will
                be removed.</li>
              </ul></b-message>
                <img src="@/assets/img/help/missing/remove-missing-values.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    Here entire individuals (rows) or variables (columns) are
                    removed (based on a maximum acceptable percentage of missingness).
                    Be aware that, in all cases, this method induces strong biases
                    when values are not missing at random. However, it is advised
                    to use it if all missing values are concentrated in a few
                    individuals and/or variables.
                    <br><br>
                    Check which method and percentage lead to the highest number
                    of removed missing values (dark red), while maintaining a
                    small number of non missing values removed (light red).
                    Remaining missing values after removal of the rows/columns
                    are in dark green.
                  </b-message>

                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    When the resulting dataset is satisfactory, save it by clicking on &nbsp;
                    <img style="height:100% !important;float:none" src="@/assets/img/help/missing/icone-extract-inactif.png">
                    .
                  </b-message>

            </div>
            </div>
</template>
<script>
export default {
  name: 'FullRMissingRemove'
}
</script>
