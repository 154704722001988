<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Self-organizing map" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/som/bio-read-map.png"/>
                <div class="content" style="padding-top: 530px;">
                  <img class="couche2" src="@/assets/img/help/som/self-organizing-map.png"/><br>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    The map should be understood as an <strong>organized
                    clustering</strong>: each circle of the map is a cluster
                    (also called a <i>neuron</i> or a <i>unit</i>) and its
                    color and size are proportional to the number of individuals
                    that it contains. The closer two clusters are to each other
                    on the map, the more similar are the individuals from these
                    two clusters.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Summary">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/som/bio-reflexion.png" style="padding-top: 5px;"/>
                <div class="content">
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    <strong>Quality criteria</strong>:
                      <li><i>topographic error</i>: is a measure of how well the
                      map is organized (<i>i.e.</i>, if two clusters are close,
                      can you trust the fact that corresponding individuals are
                      similar?). This number is always between 0 (best possible
                      organization) and 1 (worst possible organization), with 0
                      being the value expected for small maps;</li>
                      <li><i>quantization error</i>: is a measure of the clustering
                      quality (very similar to within-dispersion in clustering).
                      This number is always non negative (the smaller its value,
                      the better the clustering).</li>
                  </b-message>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    <strong>Correlation ratio</strong>: This table displays the
                    20 first variables with the largest percentage of inertia
                    reproduced by the clusters of the map. The largest the
                    percentage of inertia, the more relevant the corresponding
                    variable is to explain differences between clusters.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Cluster view">
            <div class="box">
              <div class="content">
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/go-inactif.png"/>Once the
                  dataset containing cluster memberships is extracted, you can use
                  it in other analyses or check it in menu “My workspace”.
                </b-message>
              </div>
            </div>
          </b-tab-item>

      </b-tabs>
</template>
<script>
export default {
  name: 'FullRSOMStep'
}
</script>
