<template>
          <div class="box">
            <div class="content">
              <b-message type="is-info" >
                <img src="@/assets/img/help/general/loupe.png"/>
                <strong>Quality of the PLS-DA is given by the classification
                error rate</strong>. A classification error rate equal to 0.15
                means that predicting the target variable with PLS-DA leads to
                15% of wrong predictions (based on 5-fold cross-validation
                strategy).

                The “Classification error rate” plot is used to choose the
                number of components in PLS-DA as the one minimizing the error
                rate. The <strong>balanced error rate</strong> is a
                complementary information where error rate is the average of the
                error rates for all the levels of the target variable. It is
                especially useful when the target variable has very unbalanced
                number of observed individuals between levels.
              </b-message>

              <b-message type="is-info" >
                <img src="@/assets/img/help/general/attention-actif.png"/>
                Contrary to PCA, <strong>reproduced inertia is not an objective
                of PLS-DA</strong> that finds the projection best suited to
                distinguish between levels of the target variable. These plots
                are given as mere information but can not be directly use to
                choose the number of PLS components.
              </b-message>
            </div>
          </div>
</template>
<script>
export default {
  name: 'FullRPLSDAStep'
}
</script>
