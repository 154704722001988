<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Heatmaps" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/missing/bio-identify-missingness.png"/>
                <img class="couche2" src="@/assets/img/help/missing/bio-missingness.png"/>
              </div>
              <div class="has-text-centered">
                <br><br>
                  <span class="title-help">Visually compare the two heatmaps (raw/organized).</span>
              </div >
              <div class="content" style="padding-top: 400px;">
                   <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    <strong>They look alike</strong>: the values are probably
                    missing completely at random.<br>
                    Consider imputing missing values (if the proportion of
                    missing is not too high, smaller than 30% for instance).
                   </b-message>
                   <hr><br>
                  <img src="@/assets/img/help/missing/bio-notes.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/attention-actif.png"/>
                    <strong>They are very different</strong>: this indicates
                    specific patterns of missing values that might not be
                    random.<br>
                    Imputation or row/column removal must be performed with care
                    because they might induce biases in subsequent analyses.
                  </b-message>
            </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Barplots">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/missing/bio-barplots.png"/>
                <img class="couche2" src="@/assets/img/help/missing/1-barplot.png"/>
              </div>
              <div class="content"  style="padding-top: 500px;">
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    The barplot has a <strong>regular decreasing shape</strong>
                    with roughly equal steps: this indicates that values are
                    probably missing completely at random.<br>
                    Consider imputing missing values (if the proportion of
                    missing is not too high, smaller than 30% for instance).
                  </b-message>
                  <img src="@/assets/img/help/missing/2-barplot.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/attention-actif.png"/>
                    The barplot has <strong>steps with various heights</strong>.
                    <br>
                    Imputation or row/column removal must be performed with
                    care because they might induce biases in subsequent
                    analyses.<br>
                    <strong>Does the pattern make sense?</strong> Is it possible
                    to link missingness patterns with a factor of the
                    experimental design? If so, consider imputing by the levels
                    of this factor.
                  </b-message>
                </div>
            </div>
          </b-tab-item>
      </b-tabs>
</template>
<script>
export default {
  name: 'FullRMissingOverview'
}
</script>
