<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Plots" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/explore/bio-read-plots.png"/>
                <div class="content" style="padding-top: 530px;">
                  <img class="couche2" src="@/assets/img/help/explore/boxplot-stripchart.png" style="height:25%"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    <strong>Stripcharts and boxplots</strong> are useful to
                    identify <strong>outliers</strong>. Outliers can be due to
                    an error in data (in this case, correct them or remove the
                    corresponding individual using the menu “Edit”). If they
                    are not due to an error, check these individuals closer to
                    understand why they exhibit such atypical values.
                  </b-message>
                  <hr><br>
                  <img src="@/assets/img/help/explore/all-plots.png" style="width:75%"/>
                  <div class="columns" style="padding-top: 40px;">
                    <div class="column is-three-fifths">
                      <img src="@/assets/img/help/explore/symmetric.png"/>
                    </div>
                    <div class="column">
                      <b-message type="is-info" >
                        <img src="@/assets/img/help/general/loupe.png"/>
                        <strong>Histograms, boxplots, violin plots and density
                        plots</strong> are useful to assess the <strong>symmetry of
                        the distribution</strong> or to identify multi-modal
                        distributions. Asymmetric distributions or multi-modal
                        distributions can indicate a deviation to gaussianity.
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-one-quarter">
                      <img src="@/assets/img/help/explore/multimodality.png"/>
                    </div>
                    <div class="column">
                      <b-message type="is-info">
                        <img src="@/assets/img/help/general/go-inactif.png"/>
                        If the distribution exhibits a multi-modal shape, explore
                        your variables along with categorical variables to find if
                        one can explain this distribution (panel “2 variables”).
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-one-quarter">
                      <img src="@/assets/img/help/explore/asymmetric.png"/>
                    </div>
                    <div class="column">
                      <b-message type="is-info">
                        <img src="@/assets/img/help/general/go-inactif.png"/>
                        If the distribution is asymmetric and you need it to be
                        symmetric (to perform a Student test for instance), try a
                        log-transformation from menu “Edit”.
                      </b-message>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Summary">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/explore/bio-interpret-summaries.png"/>
                <div class="has-text-centered">
                  <br><br>
                  <span class="title-help">How to interpret numerical summaries for <a href="#num">numerical
                  variables</a> or <a href="#cat">categorical variables</a>?</span>
                </div>
                <div class="content" style="padding-top: 160px;">
                  <h4 class="title is-4 couche2" id="num" style="padding-top: 50px;">Numerical variables</h4>
                  <img src="@/assets/img/help/explore/max-min-values.png"/><br>
                  <b-message type="is-info">
                    <img src="@/assets/img/help/general/go-inactif.png"/>
                    Are minimum and maximum values consistent with your knowledge?
                    For instance, 453 cm is probably not relevant for the height of
                    a Human. In this case, check and correct or remove these data
                    (from menu “Edit”).
                  </b-message>
                  <img src="@/assets/img/help/explore/median-mean-values.png" style="padding-top: 10px;"/>
                  <b-message type="is-info">
                    <img src="@/assets/img/help/general/go-inactif.png"/>
                    A difference between mean and median can indicate that the
                    variable distribution is asymmetric. Check with the histogram,
                    the boxplot or the density plot.
                  </b-message>
                  <img src="@/assets/img/help/explore/missing-values.png" style="padding-top: 10px;"/>
                  <b-message type="is-info">
                    <img src="@/assets/img/help/general/go-inactif.png"/>
                    Did you expect missing values or not? If any, missing values can
                    be explored and/or removed/corrected from Menu “Edit”.
                  </b-message>
                  <img src="@/assets/img/help/explore/standard-deviation1.png" style="padding-top: 10px;"/>
                  <img src="@/assets/img/help/explore/spread-dispersion.png"/>
                  <b-message type="is-info">
                    <img src="@/assets/img/help/general/go-inactif.png"/>
                    “Sd” is for Standard deviation. It is a measure of spread or
                    dispersion and it tells us about the variability of the values
                    around the mean. The higher the Sd the more scattered the values;
                    the lower the Sd, the more concentrated the values. A Sd equal
                    to zero indicates that all the values are equal (and are thus
                    equal to the mean).
                  </b-message>
                  <br><br>
                  <h4 class="title is-4" id="cat">Categorical variables</h4>
                  <img src="@/assets/img/help/explore/categories.png"/><br>
                  <b-message type="is-info">
                    <img src="@/assets/img/help/general/go-inactif.png"/>
                    Is the reported number of categories correct? Did you expect
                    such a distribution of individuals among categories? (Is it
                    consistent with the design of your experiment?)<br>
                    If you have <strong>many categories</strong>, the barplot can be
                    useful to better assess their distribution. If you have one or
                    several <strong>very rare categories</strong> (containing only
                    a few number of individuals), you might want to recode them or
                    to remove them from Menu “Edit”.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

      </b-tabs>
</template>
<script>
export default {
  name: 'FullRUnivariate'
}
</script>
