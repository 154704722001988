<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Correction for multiple testing" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/diff-analysis/bio-tests.png"/>
                <div class="content" style="padding-top: 470px;">
                  <img class="couche2" src="@/assets/img/help/diff-analysis/non-parametric-tests.png" style="padding-top: 10px;"/>
                  <img src="@/assets/img/help/diff-analysis/parametric-tests.png"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    <strong>Correction for multiple testing</strong> (e.g.,
                    computation of adjusted p-values) is performed globally for
                    non-parametric tests and tests for count data (based on
                    <strong>edgeR</strong>). For parametric tests, the correction
                    is performed in two tests: first, for every variable, p-values
                    based on <strong>Tukey HDM</strong> (“honest differences in
                    means”) are computed (and reported in column “p-values”).
                    These p-values include a correction specific to the case where
                    all level pairs of the same variable are tested in turns.
                    Then, these p-values are further corrected at the condition
                    pair level using a standard approach (Benjamini &amp; Hochberg
                    correction or Bonferonni correction).
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Forest plot" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/diff-analysis/bio-forest-plots.png"/>
                <div class="content" style="padding-top: 440px;">
                  <img class="couche2" src="@/assets/img/help/diff-analysis/forest-plots.png" style="padding-top: 30px;"/>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    This plot is a graphical output of the post-hoc tests (tests
                    performed on pairs of levels of the categorical variable when
                    it has more than two levels and if the global test for 1-way
                    ANOVA is found significant). The segments represent the
                    confidence interval for each pairwise comparison: if 0 belongs
                    to this interval the difference between the two means is not
                    significant. In this plot, <strong>the correction</strong>
                    is performed <strong>only between pairs of conditions for
                    this variable</strong> (not accounting for all variables in
                    the dataset).
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

      </b-tabs>
</template>
<script>
export default {
  name: 'FullRPosthocTests'
}
</script>
