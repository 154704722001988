<template>
      <div class="box">
        <div class="content">
          <b-message type="is-info" >
            <img src="@/assets/img/help/general/go-inactif.png"/>When the
            dataset is extracted, you can use it in other analyses or check
            it in menu “My workspace”.
          </b-message>
        </div>
      </div>
</template>
<script>
export default {
  name: 'FullRExtractObj'
}
</script>
