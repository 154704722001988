<template>
  <div class="box">
    <img class="bulle" src="@/assets/img/help/edit/HelpREdit.png"/>
    <div class="content">
      <img src="@/assets/img/help/edit/edit.gif"/><br>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FullREdit'
}
</script>
<style scoped>

</style>
