<template>
          <div class="box">
            <div class="content">
              <b-message type="is-info" >
                <img src="@/assets/img/help/general/go-inactif.png"/>Explore
                your clusters with the heatmap and the PCA projection or the
                frequency table. If you want to use your clusters in other
                analyses “Create a new dataset” in the “Clusters” panel and
                check it in menu “My workspace”.
              </b-message>
            </div>
          </div>
</template>
<script>
export default {
  name: 'FullRCutClustering'
}
</script>
