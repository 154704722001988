<template>
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/som/bio-super-clustering.png"/>
                <div class="content" style="padding-top: 171px;">
                  <div class="columns">
                    <div class="column is-half">
                      <img src="@/assets/img/help/som/super-clustering.png" style="padding-top: 40px;"/>
                    </div>
                    <div class="column is-half">
                      <b-message type="is-info" >
                        <img src="@/assets/img/help/general/loupe.png"/>
                        Superclustering is a hierarchical clustering performed on
                        the cluster prototypes (tips of the dendrogram are thus
                        prototypes)(*). It groups together similar clusters.
                        <br><br>
                        <i>(*) Prototypes are special individuals, representative
                        of their cluster. They are approximately computed as centers
                        of gravity of the individuals in this cluster.</i>
                      </b-message>
                    </div>
                  </div>
                  <div class="columns" style="padding-top: 25px;">
                    <div class="column is-half">
                      <img src="@/assets/img/help/som/color-super-cluster.png"/>
                    </div>
                    <div class="column is-half">
                      <br><br><br><br>
                      <b-message type="is-info">
                        <img src="@/assets/img/help/general/loupe.png"/>
                        Colors on the map indicates to which super-cluster the
                        corresponding cluster is affected. Circle colors match
                        the colors of the dendrogram and size of the circles are
                        proportional to the number of individuals classified
                        in this cluster.
                      </b-message>
                    </div>
                  </div>
                  <span>
                    <b-message type="is-info">
                      <img src="@/assets/img/help/general/go-inactif.png"/>
                      To choose the number of super-clusters, try to find a gap
                      in the branch length evolution.
                    </b-message>
                  </span>
                </div>
              </div>
            </div>
</template>
<script>
export default {
  name: 'FullRPlotSomSuper'
}
</script>
