<template>
          <div class="box">
            <div class="multicouche">
              <img class="bulle couche1" src="@/assets/img/help/mfa/bio-combine.png"/>
              <div class="content" style="padding-top: 940px;">
              <div class="has-text-centered couche2">
                <img src="@/assets/img/help/mfa/venn-diagram.png" style="padding-top: 30px;"/>
                <br><br>
                <img src="@/assets/img/help/mfa/upset-plots.png"/>
              </div>
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/loupe.png"/>
                  <strong>Venn diagram</strong> and <strong>upset
                  plots</strong> are used to understand how many individuals
                  are common / specific to each dataset. Only individuals
                  <strong>common to all integrated datasets</strong> are
                  used in the analysis.
                </b-message>
              </div>
            </div>
          </div>
</template>
<script>
export default {
  name: 'FullRCombineDataset'
}
</script>
