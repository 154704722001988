<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Understand the screen" selected="true">
            <div class="box">
              <div class="content">
                <img src="@/assets/img/help/import/understand-screen.gif"/>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Effect of an incorrect column separator">
            <div class="box">
              <div class="content">
                <img src="@/assets/img/help/import/effect-incorrect-separator.gif"/>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Effect of an incorrect decimal mark">
            <div class="box">
              <div class="content">
                <img src="@/assets/img/help/import/effect-incorrect-decimal-mark.gif"/>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Effect of an incorrect quote option">
            <div class="box">
              <div class="content">
                <img src="@/assets/img/help/import/quote-selection.gif"/>
              </div>
            </div>
          </b-tab-item>

      </b-tabs>
</template>
<script>
export default {
  name: 'FullRImportDataset'
}
</script>
